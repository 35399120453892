
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from '../../service/UserService';// Ensure this path is correct

export default function RegistrationPage() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    password: '',
    fileNumber: '',
    standNumber: '',
    yearOfPurchase: '',
    director: '', // Default to one of the options
    squareMetres: '',
    descriptionOfStand: '',
    cellNumber: '',
    role: 'USER',
    standPrice: '',
    payments: [{ receiptNumber: '', paymentDate: '', amount: '' }],
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePaymentChange = (index, e) => {
    const { name, value } = e.target;
    const newPayments = [...formData.payments];
    newPayments[index] = { ...newPayments[index], [name]: value };
    setFormData((prevData) => ({
      ...prevData,
      payments: newPayments,
    }));
  };

  const addPaymentField = () => {
    setFormData((prevData) => ({
      ...prevData,
      payments: [...prevData.payments, { receiptNumber: '', paymentDate: '', amount: '' }],
    }));
  };

  const removePaymentField = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      payments: prevData.payments.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    let validationErrors = {};
    if (!formData.email) validationErrors.email = 'Email is required';
    if (!/\S+@\S+\.\S+/.test(formData.email)) validationErrors.email = 'Invalid email format';
    if (!formData.password) validationErrors.password = 'Password is required';
    if (formData.password.length < 6) validationErrors.password = 'Password must be at least 6 characters long';
    if (!formData.fileNumber) validationErrors.fileNumber = 'File number is required';

    if (!formData.name) validationErrors.name = 'Name  is required';
    if (!formData.squareMetres) validationErrors.squareMetres = 'Stand Square Metres is required';
    if (!formData.director) validationErrors.director = 'Director of the stand is required';
    if (!formData.role) validationErrors.role = 'Role is required';
    if (!formData.descriptionOfStand) validationErrors.descriptionOfStand = 'Description of the stand is required';

    if (!formData.standNumber) validationErrors.standNumber = 'Stand number is required';
    if (!formData.yearOfPurchase) validationErrors.yearOfPurchase = 'Date of Purchase is required';
    if (!formData.cellNumber) validationErrors.cellNumber = 'Cell number is required';
    if (!formData.standPrice || isNaN(formData.standPrice)) validationErrors.standPrice = 'Valid stand price is required';
    formData.payments.forEach((payment, index) => {
      if (!payment.receiptNumber) validationErrors[`receiptNumber-${index}`] = 'Receipt number is required';
      if (!payment.paymentDate) validationErrors[`paymentDate-${index}`] = 'Payment date is required';
      if (!payment.amount || isNaN(payment.amount)) validationErrors[`amount-${index}`] = 'Valid amount is required';
    });

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0; // Returns true if there are no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // If validation fails, stop submission
    }

    try {
      const token = localStorage.getItem('token');
      await UserService.register(formData, token);

      setFormData({
        email: '',
        name: '',
        password: '',
        fileNumber: '',
        standNumber: '',
        yearOfPurchase: '',
        director: 'DIRECTOR1', // Reset to default
        squareMetres: '',
        descriptionOfStand: '',
        cellNumber: '',
        role: 'USER',
        standPrice: '',
        payments: [{ receiptNumber: '', paymentDate: '', amount: '' }],
      });
      alert('User registered successfully');
      navigate('/admindashboard/registration');
    } catch (error) {
      console.error('Error registering user:', error);
      alert('An error occurred while registering user');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you share.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.email && <p className="text-red-600 text-sm">{errors.email}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.name && <p className="text-red-600 text-sm">{errors.name}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.password && <p className="text-red-600 text-sm">{errors.password}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="fileNumber" className="block text-sm font-medium leading-6 text-gray-900">
                File Number
              </label>
              <div className="mt-2">
                <input
                  id="fileNumber"
                  name="fileNumber"
                  type="text"
                  value={formData.fileNumber}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.fileNumber && <p className="text-red-600 text-sm">{errors.fileNumber}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="standNumber" className="block text-sm font-medium leading-6 text-gray-900">
                Stand Number
              </label>
              <div className="mt-2">
                <input
                  id="standNumber"
                  name="standNumber"
                  type="text"
                  value={formData.standNumber}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-
focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.standNumber && <p className="text-red-600 text-sm">{errors.standNumber}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
  <label htmlFor="yearOfpurchase" className="block text-sm font-medium leading-6 text-gray-900">
    Date of Purchase
  </label>
  <div className="mt-2">
    <input
      id="yearOfPurchase"
      name="yearOfPurchase"
      type="date"
      value={formData.yearOfPurchase}
      onChange={handleInputChange}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      max="2024-08-25" // Sets the max date allowed
    />
    {errors.yearOfPurchase && <p className="text-red-600 text-sm">{errors.yearOfPurchase}</p>}
  </div>
</div>

<div className="sm:col-span-3">
      <label htmlFor="director" className="block text-sm font-medium leading-6 text-gray-900">
        Director
      </label>
      <div className="mt-2">
      <select
  id="director"
  name="director"
  value={formData.director}
  onChange={handleInputChange}
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
>
  <option value="">Select Director</option>
  <option value="Mubaiwa">Mubaiwa</option>
  <option value="Tangawabaiwa">Tangawabaiwa</option>
  <option value="Mbaiwa">Mbaiwa</option>
</select>
        {errors.director && <p className="text-red-600 text-sm">{errors.director}</p>}
      </div>
    </div>



            <div className="sm:col-span-3">
              <label htmlFor="squareMetres" className="block text-sm font-medium leading-6 text-gray-900">
                Square Metres
              </label>
              <div className="mt-2">
                <input
                  id="squareMetres"
                  name="squareMetres"
                  type="text"
                  value={formData.squareMetres}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.squareMetres && <p className="text-red-600 text-sm">{errors.squareMetres}</p>}
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="descriptionofStand" className="block text-sm font-medium leading-6 text-gray-900">
                Description of Stand
              </label>
              <div className="mt-2">
                <textarea
                  id="descriptionOfStand"
                  name="descriptionOfStand"
                  rows={15}
                  value={formData.descriptionOfStand}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.descriptionOfStand && <p className="text-red-600 text-sm">{errors.descriptionOfStand}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="cellNumber" className="block text-sm font-medium leading-6 text-gray-900">
                Cell Number
              </label>
              <div className="mt-2">
                <input
                  id="cellNumber"
                  name="cellNumber"
                  type="text"
                  value={formData.cellNumber}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.cellNumber && <p className="text-red-600 text-sm">{errors.cellNumber}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
  <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
    Role
  </label>
  <div className="mt-2">
    <select
      id="role"
      name="role"
      value={formData.role}
      onChange={handleInputChange}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    >
      <option value="USER">User</option>
    </select>
    {errors.role && <p className="text-red-600 text-sm">{errors.role}</p>}
  </div>
</div>


            <div className="sm:col-span-3">
              <label htmlFor="standPrice" className="block text-sm font-medium leading-6 text-gray-900">
                Stand Price
              </label>
              <div className="mt-2">
                <input
                  id="standPrice"
                  name="standPrice"
                  type="number"
                  value={formData.standPrice}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.standPrice && <p className="text-red-600 text-sm">{errors.standPrice}</p>}
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="payments" className="block text-sm font-medium leading-6 text-gray-900">
                Payments
              </label>
              {formData.payments.map((payment, index) => (
                <div key={index} className="mt-2 space-y-4">
                  <div className="flex items-center space-x-4">
                    <input
                      id={`receiptNumber-${index}`}
                      name="receiptNumber"
                      type="text"
                      placeholder="Receipt Number"
                      value={payment.receiptNumber}
                      onChange={(e) => handlePaymentChange(index, e)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <input
                      id={`paymentDate-${index}`}
                      name="paymentDate"
                      type="date"
                      value={payment.paymentDate}
                      onChange={(e) => handlePaymentChange(index, e)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <input
                      id={`amount-${index}`}
                      name="amount"
                      type="number"
                      placeholder="Amount"
                      value={payment.amount}
                      onChange={(e) => handlePaymentChange(index, e)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <button
                      type="button"
                      onClick={() => removePaymentField(index)}
                      className="text-sm font-semibold leading-6 text-red-600"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={addPaymentField}
                className="mt-2 text-sm font-semibold leading-6 text-indigo-600"
              >
                Add Payment
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          onClick={() => navigate('/admindashboard/registration')}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}

