import React, { useEffect, useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import UserService from '../../service/UserService'; // Assuming UserService handles authentication

export default function Adminheader({ setSidebarOpen }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(UserService.isAuthenticated());
  }, []);

  if (typeof setSidebarOpen !== 'function') {
    console.error('setSidebarOpen should be a function');
    return null;
  }

  return (
    <>
      {/* Mobile Header */}
      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-indigo-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden w-full">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-indigo-200"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
        {isAuthenticated && (
          <a href="#">
            <span className="sr-only">Your profile</span>
            <div className="h-8 w-8 rounded-full bg-indigo-700"></div>
          </a>
        )}
      </div>

      {/* Larger Screen Header */}
      <div className="hidden lg:flex items-center justify-between bg-indigo-600 w-full px-4 py-4 shadow-sm sm:px-6">
        <div className="text-lg font-semibold leading-6 text-white">Dashboard </div>
        {isAuthenticated && (
          <a href="#">
            <span className="sr-only">Your profile</span>
            <div className="h-10 w-10 rounded-full bg-indigo-700"></div>
          </a>
        )}
      </div>
    </>
  );
}
