
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from '../../service/UserService';

function AdminProfile() {
  const [clients, setClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async (query = '') => {
    try {
      const token = localStorage.getItem('token');
      const response = query
        ? await UserService.searchUsers(query, token)
        : await UserService.getAllUsers(token);

      if (response && response.ourUsersList) {
        setClients(response.ourUsersList);
      } else {
        setError('ourUsersList is missing in the response');
      }
    } catch (error) {
      console.error('Error fetching client information:', error);
      setError('Failed to load client information');
    } finally {
      setIsLoaded(true);
    }
  };

  const handleCardClick = (client) => {
    localStorage.setItem('selectedClient', JSON.stringify(client));
    navigate(`/client/${client.id}`, { state: { client } });
  };

  const deleteUser = async (userId, event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up to the card
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this user?');
      const token = localStorage.getItem('token');
      
      if (confirmDelete) {
        await UserService.deleteUser(userId, token);
        fetchClients(searchQuery); // Refresh the list after deletion
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    fetchClients(searchQuery);
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Search Section */}
      <div className="mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search for users..."
          className="border border-gray-300 p-2 rounded"
        />
        <button
          onClick={handleSearch}
          className="ml-2 bg-blue-500 text-white px-4 py-2 rounded"
        >
          Search
        </button>
      </div>

      {/* Clients List */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {clients.map((client) => (
          <div
            key={client.id}
            className="bg-white shadow-md rounded-lg p-4 border border-gray-200 cursor-pointer"
            onClick={() => handleCardClick(client)}
          >
            <img
              src="https://tailwindui.com/img/logos/48x48/tuple.svg"
              alt={client.name}
              className="h-12 w-12 rounded-full object-cover"
            />
            <h2 className="text-lg font-semibold">{client.name}</h2>
            <p className="text-gray-600">File Number: {client.fileNumber}</p>
            <p className="text-gray-600">Stand Number: {client.standNumber}</p>
            <p className="text-gray-600">Year of Purchase: {client.yearOfPurchase}</p>
            <button
              className="mt-2 text-red-500 hover:text-red-700"
              onClick={(event) => deleteUser(client.id, event)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminProfile;








// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import UserService from '../../service/UserService';

// function AdminProfile() {
//   const [clients, setClients] = useState([]);
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchClients();
//   }, []);

//   const fetchClients = async () => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await UserService.getAllUsers(token);

//       if (response && response.ourUsersList) {
//         setClients(response.ourUsersList);
//       } else {
//         setError('ourUsersList is missing in the response');
//       }
//     } catch (error) {
//       console.error('Error fetching client information:', error);
//       setError('Failed to load client information');
//     } finally {
//       setIsLoaded(true);
//     }
//   };

//   const handleCardClick = (client) => {
//     localStorage.setItem('selectedClient', JSON.stringify(client));
//     navigate(`/client/${client.id}`, { state: { client } });
//   };

//   const deleteUser = async (userId, event) => {
//     event.stopPropagation(); // Prevent the click event from bubbling up to the card
//     try {
//       const confirmDelete = window.confirm('Are you sure you want to delete this user?');
//       const token = localStorage.getItem('token');
      
//       if (confirmDelete) {
//         await UserService.deleteUser(userId, token);
//         fetchClients(); // Refresh the list after deletion
//       }
//     } catch (error) {
//       console.error('Error deleting user:', error);
//     }
//   };

//   if (error) {
//     return <div>{error}</div>;
//   }

//   if (!isLoaded) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
//       {clients.map((client) => (
//         <div
//           key={client.id}
//           className="bg-white shadow-md rounded-lg p-4 border border-gray-200 cursor-pointer"
//           onClick={() => handleCardClick(client)}
//         >
//           <img
//             src="https://tailwindui.com/img/logos/48x48/tuple.svg"
//             alt={client.name}
//             className="h-12 w-12 rounded-full object-cover"
//           />
//           <h2 className="text-lg font-semibold">{client.name}</h2>
//           <p className="text-gray-600">File Number: {client.fileNumber}</p>
//           <p className="text-gray-600">Stand Number: {client.standNumber}</p>
//           <p className="text-gray-600">Year of Purchase: {client.yearOfPurchase}</p>
//           <button
//             className="mt-2 text-red-500 hover:text-red-700"
//             onClick={(event) => deleteUser(client.id, event)}
//           >
//             Delete
//           </button>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default AdminProfile;
