import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import UserService from '../../service/UserService';
import classNames from 'classnames';

function ProfilePage() {
  const [profileInfo, setProfileInfo] = useState({
    name: '',
    fileNumber: '',
    standNumber: '',
    yearOfPurchase: '',
    director: '',
    squareMetres: '',
    descriptionOfStand: '',
    cellNumber: '',
    payments: [],
    standPrice: 0,
    totalPayments: 0,
    balance: 0,
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu visibility

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const fetchProfileInfo = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve the token from localStorage
      const response = await UserService.getYourProfile(token);
      setProfileInfo(response.ourUsers);
    } catch (error) {
      console.error('Error fetching profile information:', error);
    }
  };

  return (
    <div className="max-w-3xl mx-auto py-10">
      <div className="overflow-hidden rounded-xl border border-gray-200 bg-white">
        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          <img
            src="https://tailwindui.com/img/logos/48x48/tuple.svg" // Placeholder image URL
            alt={profileInfo.name}
            className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
          />
          <div className="text-lg font-medium leading-6 text-gray-900">
            {profileInfo.name}
          </div>
          <Menu as="div" className="relative ml-auto">
            <Menu.Button
              className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500"
              onClick={() => setIsMenuOpen(!isMenuOpen)} // Toggle menu visibility
            >
              <span className="sr-only">Open options</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            {isMenuOpen && (
              <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={`/update-user/${profileInfo.id}`}
                      className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                      )}
                    >
                      Update Profile
                    </Link>
                  )}
                </Menu.Item>
              </Menu.Items>
            )}
          </Menu>
        </div>
        <dl className="divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          {/* Profile details here */}
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Full Name</dt>
            <dd className="text-gray-700">{profileInfo.name}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">File Number</dt>
            <dd className="text-gray-700">{profileInfo.fileNumber}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Stand Number</dt>
            <dd className="text-gray-700">{profileInfo.standNumber}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Year of Purchase</dt>
            <dd className="text-gray-700">{profileInfo.yearOfPurchase}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Director</dt>
            <dd className="text-gray-700">{profileInfo.director}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Size of Stand</dt>
            <dd className="text-gray-700">{profileInfo.squareMetres}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Description</dt>
            <dd className="text-gray-700">{profileInfo.descriptionOfStand}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Stand Price</dt>
            <dd className="text-gray-700">{profileInfo.standPrice}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Total Payments</dt>
            <dd className="text-gray-700">{profileInfo.totalPayments}</dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Balance</dt>
            <dd className="text-gray-700">{profileInfo.balance}</dd>
          </div>
          <div className="flex flex-col gap-y-2 py-3">
            <dt className="text-gray-500">Payments</dt>
            {profileInfo.payments.map((payment) => (
              <dd key={payment.id} className="text-gray-700">
                {`Amount: $${payment.amount}, Receipt: ${payment.receiptNumber}, Date: ${payment.paymentDate || 'N/A'}`}
              </dd>
            ))}
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Cell Number</dt>
            <dd className="text-gray-700">{profileInfo.cellNumber}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default ProfilePage;
