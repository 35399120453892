import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import MobileSidebar from './MobileSidebar';
import Header from './Header';
import ProfilePage from '../userspage/ProfilePage';
import UserService from '../../service/UserService'; // Make sure to import your UserService

export default function UserDashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!UserService.isAuthenticated()) {
      // Redirect to login if the user is not authenticated
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div>
      <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar />
      <Header setSidebarOpen={setSidebarOpen} />

      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <ProfilePage />
        </div>
      </main>
    </div>
  );
}
