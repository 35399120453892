


import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import AdminSidebar from '../admindashboard/AdminSidebar';
import Adminheader from '../admindashboard/Adminheader';
import AdminMobilesidebar from '../admindashboard/AdminMobilesidebar';
 // Adjust the import path if necessary
// Adjust the import path if necessary


function DetailView() {
  const location = useLocation();
  const [client, setClient] = useState(location.state?.client);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    console.log(client.payments);
    if (!client) {
      const storedClient = localStorage.getItem('selectedClient');
      if (storedClient) {
        setClient(JSON.parse(storedClient));
      } else {
        console.error("No client data found. Please ensure the client data is passed correctly.");
      }
    }
  }, [client]);

  if (!client) {
    return <div className="text-center mt-8 text-gray-600">Loading client data...</div>;
  }

  return (
    <div className="flex min-h-screen">
      {/* Mobile Sidebar */}
      <AdminMobilesidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Desktop Sidebar */}
      <AdminSidebar />

      {/* Main content */}
      <div className="flex-1 flex flex-col lg:pl-64">
        {/* Header */}
        <Adminheader setSidebarOpen={setSidebarOpen} />

        {/* Content */}
        <div className="max-w-4xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-semibold leading-7 text-gray-900">Client Information</h3>
            <p className="mt-1 text-sm text-gray-500">Details about the client and their financials.</p>
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              {[
                { label: "Full Name", value: client.name },
                { label: "Email", value: client.email },
                { label: "File Number", value: client.fileNumber },
                { label: "Stand Number", value: client.standNumber },
                { label: "Year of Purchase", value: client.yearOfPurchase },
                { label: "Director", value: client.director },
                { label: "Cell Number", value: client.cellNumber },
                { label: "Stand Description", value: client.descriptionOfStand },
                { label: "Square Metres", value: client.squareMetres },
                { label: "Stand Price", value: `$${client.standPrice.toFixed(2)}` },
                { label: "Total Payments", value: `$${client.totalPayments.toFixed(2)}` },
                { label: "Balance", value: `$${client.balance.toFixed(2)}` },
              ].map((item) => (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" key={item.label}>
                  <dt className="text-sm font-medium leading-6 text-gray-900">{item.label}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.value}</dd>
                </div>
              ))}

              {/* Payments */}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Payments</dt>
                <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {client.payments.length > 0 ? (
                    <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                      {client.payments.map((payment) => (
                        <li key={payment.id} className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                          <div className="flex w-0 flex-1 items-center">
                            <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <div className="ml-4 flex min-w-0 flex-col sm:flex-row gap-4">
  <span className="font-medium">Amount: ${payment.amount.toFixed(2)}</span>
  <span className="text-gray-400">Receipt Number: {payment.receiptNumber}</span>
  <span className="text-gray-400">Date: {payment.paymentDate}</span>
</div>

                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-gray-500">No payments recorded</p>
                  )}
                </dd>
              </div>
            </dl>
          </div>

          <button
            onClick={() => window.history.back()}
            className="mt-6 w-full sm:w-auto inline-flex items-center justify-center px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default DetailView;
