



import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './components/auth/LoginPage';
import AdminDashboard from './components/admindashboard/admindashboard'; // Correct the import path
import UserDashboard from './components/userdashboard/UserDashboard';
import ProfilePage from './components/userspage/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute';
import AdminProfile from './components/admindashboard/AdminProfile';
import RegistrationPage from './components/auth/RegistrationPage';
import DetailView from './components/other/DetailView';
import AddPayment from './components/other/AddPayment';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        {/* Admin dashboard routes */}
        <Route
          path="/admindashboard/*"
          element={
            <ProtectedRoute role="ADMIN">
              <AdminDashboard />
            </ProtectedRoute>
          }
        >
          {/* Default admin dashboard home with AdminProfile */}
          <Route path="" element={<AdminProfile />} />
          <Route path="profile" element={<AdminProfile />} />
          <Route path="registration" element={<RegistrationPage />} />
          <Route path="add-payment" element={<AddPayment />} />  {/* New route for adding payments */}
        </Route>

        {/* User dashboard routes */}
        <Route
          path="/userdashboard/*"
          element={
            <ProtectedRoute role="USER">
              <UserDashboard />
            </ProtectedRoute>
          }
        >
          <Route path="profile" element={<ProfilePage />} />
        </Route>

        {/* Additional routes */}
        <Route path="/admin/profile" element={<AdminProfile />} />
        <Route path="/client/:id" element={<DetailView />} />

        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
}

export default App;

















// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import LoginPage from './components/auth/LoginPage';
// import AdminDashboard from './components/admindashboard/admindashboard'; // Adjust path if necessary
// import UserDashboard from './components/userdashboard/UserDashboard';
// import ProfilePage from './components/userspage/ProfilePage';
// import ProtectedRoute from './components/ProtectedRoute';
// import AdminProfile from './components/admindashboard/AdminProfile';
// import RegistrationPage from './components/auth/RegistrationPage';
// import DetailView from './components/other/DetailView';
// import AddPayment from './components/other/AddPayment';
// import UpdateUserForm from './components/other/UpdateUser'; // Import UpdateUserForm

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<LoginPage />} />

//         {/* Admin dashboard routes */}
//         <Route
//           path="/admindashboard/*"
//           element={
//             <ProtectedRoute role="ADMIN">
//               <AdminDashboard />
//             </ProtectedRoute>
//           }
//         >
//           {/* Default admin dashboard home with AdminProfile */}
//           <Route path="" element={<AdminProfile />} />
//           <Route path="profile" element={<AdminProfile />} />
//           <Route path="registration" element={<RegistrationPage />} />
//           <Route path="add-payment" element={<AddPayment />} /> {/* Route for adding payments */}
//         </Route>

//         {/* User dashboard routes */}
//         <Route
//           path="/userdashboard/*"
//           element={
//             <ProtectedRoute role="USER">
//               <UserDashboard />
//             </ProtectedRoute>
//           }
//         >
//           <Route path="profile" element={<ProfilePage />} />
//         </Route>

//         {/* Additional routes */}
//         <Route path="/admin/profile" element={<AdminProfile />} />
//         <Route path="/client/:id" element={<DetailView />} />

//         {/* Route for updating the user */}
//         <Route path="/client/update/:userId" element={<UpdateUserForm />} /> {/* Update user form route */}

//         {/* Catch-all route */}
//         <Route path="*" element={<Navigate to="/login" replace />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;







// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import LoginPage from './components/auth/LoginPage';
// import AdminDashboard from './components/admindashboard/admindashboard'; // Correct the import path
// import UserDashboard from './components/userdashboard/UserDashboard';
// import ProfilePage from './components/userspage/ProfilePage';
// import ProtectedRoute from './components/ProtectedRoute';
// import AdminProfile from './components/admindashboard/AdminProfile';
// import RegistrationPage from './components/auth/RegistrationPage';

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<LoginPage />} />

//         {/* Admin dashboard routes */}
//         <Route
//           path="/admindashboard/*"
//           element={
//             <ProtectedRoute>
//               <AdminDashboard />
//             </ProtectedRoute>
//           }
//         >
//           <Route path="profile" element={<AdminProfile />} />
//           <Route path="registration" element={<RegistrationPage />} />
//         </Route>

//         {/* User dashboard routes */}
//         <Route
//           path="/userdashboard/*"
//           element={
//             <ProtectedRoute>
//               <UserDashboard />
//             </ProtectedRoute>
//           }
//         >
//           <Route path="profile" element={<ProfilePage />} />
//         </Route>

//         {/* Catch-all route */}
//         <Route path="*" element={<Navigate to="/login" replace />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;
