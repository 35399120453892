import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import Adminheader from './Adminheader';
import AdminMobilesidebar from './AdminMobilesidebar';
import UserService from '../../service/UserService';

export default function AdminDashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!UserService.isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="flex flex-col min-h-screen">
      <Adminheader setSidebarOpen={setSidebarOpen} className="fixed top-0 w-full z-10" />
      <div className="flex flex-1">
        <AdminSidebar className="fixed top-0 left-0 h-full z-20" />
        <main className="flex-1 pt-16 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">
            <Outlet /> {/* Ensure this is here for nested routes */}
          </div>
        </main>
      </div>
      <AdminMobilesidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    </div>
  );
}
