import React, { useState, useEffect } from 'react';
import { HomeIcon, DocumentTextIcon,CreditCardIcon } from '@heroicons/react/24/outline';
import UserService from '../../service/UserService';

const navigation = [
  { name: 'Admin Dashboard', to: '/admindashboard/profile', icon: HomeIcon },
  { name: 'Registration', to: '/admindashboard/registration', icon: DocumentTextIcon },
  { name: 'Add Payment', to: '/admindashboard/add-payment', icon: CreditCardIcon }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AdminSidebar() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(UserService.isAuthenticated());
  }, []);

  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (confirmLogout) {
      UserService.logout();
      window.location.href = '/login'; // Redirect to login page after logout
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:bg-indigo-600 lg:overflow-y-auto">
      <div className="flex items-center h-16 shrink-0 px-4 bg-indigo-800">
        <img
          className="h-8 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=white"
          alt="Your Company"
        />
      </div>
      <nav className="flex-1 px-2 py-4 space-y-1">
        {navigation.map((item) => (
          <a
            key={item.name}
            href={item.to}
            className={classNames(
              item.current
                ? 'bg-indigo-700 text-white'
                : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
              'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
            )}
          >
            <item.icon
              className={classNames(
                item.current ? 'text-white' : 'text-indigo-300 group-hover:text-white',
                'mr-3 h-6 w-6'
              )}
              aria-hidden="true"
            />
            {item.name}
          </a>
        ))}

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="mt-4 w-full flex items-center px-2 py-2 text-sm font-medium text-indigo-200 hover:text-white hover:bg-indigo-700 rounded-md"
        >
          <span className="mr-3 h-6 w-6 text-indigo-300 group-hover:text-white">🔒</span>
          Logout
        </button>
      </nav>
    </div>
  );
}
