import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import UserService from '../service/UserService';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = UserService.isAuthenticated(); // Implement your auth check

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;




// const ProtectedRoute = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(null); // null indicates loading state

//   useEffect(() => {
//     const checkAuth = async () => {
//       try {
//         const auth = await UserService.isAuthenticated();
//         setIsAuthenticated(auth);
//       } catch (error) {
//         console.error('Authentication check failed:', error);
//         setIsAuthenticated(false);
//       }
//     };

//     checkAuth();
//   }, []);

//   if (isAuthenticated === null) {
//     // Loading state, can show a loading spinner or nothing
//     return <div>Loading...</div>;
//   }

//   if (!isAuthenticated) {
//     return <Navigate to="/login" replace />;
//   }

//   return children;
// };

// export default ProtectedRoute;
